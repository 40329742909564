Vue.component('favoritiesListComponent', {
    data: function () {
        return {
            itemsFlag:false,
            orderSelected:null,
            favoritiesList:[],
            launchBackground:true,
        }
    },
    watch: {
        '$store.state.appState':'fetchData',
        '$store.state.suggestionList':'fetchData',
        '$store.state.orderBy':'fetchData',
        '$store.state.orderDirection':'fetchData',
        'initItem':'fetchData',
        'lastItem':'fetchData'
    },
    computed:{
        viewmode: function(){
            return this.$store.getters.getItemViewMode;
        },
        initItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset;
        },
        lastItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
        },
        moduleObject: function () {
            return {
                'green-text': this.viewmode=='module'
            }
        },
        listObject: function () {
            return {
                'green-text': this.viewmode=='list'
            }
        },
        listLoad:function(){
            if(this.itemsFlag=true || !this.itemsFlag==false)
                console.log('Dummy');
            let favoritiesList= [];
            for(let itemCode of this.$store.getters.getFavoritesItems){
                let itemLoad = this.$store.getters.getItemByCode(itemCode);
                if(itemLoad)
                    favoritiesList.push(itemLoad)
            }
            return __lodash__.orderBy(favoritiesList, this.$store.getters.getOrderBy,this.$store.getters.getOrderDirection);
        },
    },
    mounted:function(){
        if(!store.getters.getSession){
            this.$router.push({"name":"HomeLink"})
        }
        else
            this.fetchData();
    },
    methods:{
        fetchData:function(){
            let self = this;
            this.favoritiesList= [];
            let favoritiesList=[];
            let itemWithOutPrice = [];
            if(!this.orderSelected)
                for(let orderField of this.$store.getters.getSetting.ItemOrdered){
                    if(orderField.fields.ByDefault){
                        this.orderSelected = orderField.fields.internalId
                    }
                }


            for(let itemCode of this.$store.getters.getFavoritesItems){
                let itemLoad = this.$store.getters.getItemByCode(itemCode);
                if(itemLoad) {
                    favoritiesList.push(itemLoad);
                }
            }

            favoritiesList= __lodash__.orderBy(favoritiesList, this.$store.getters.getOrderBy, this.$store.getters.getOrderDirection);

            for(let itemLoad of favoritiesList.slice(this.initItem,this.lastItem)){
                if(!itemLoad.isCalculate){
                    itemWithOutPrice.push(itemLoad);
                }
            }

            console.log('Item With Out Price',itemWithOutPrice,this.initItem,this.lastItem);

            if(itemWithOutPrice.length>0){
                this.$store.dispatch('updatePrices', itemWithOutPrice).then(() => {
                    if(self.$store.getters.getOrderBy=="Price")
                        self.favoritiesList = __lodash__.orderBy(favoritiesList, self.$store.getters.getOrderBy, self.$store.getters.getOrderDirection);
                    else
                        self.favoritiesList = favoritiesList
                });
                if(this.launchBackground) {
                    itemWithOutPrice = [];
                    for(let itemLoad of favoritiesList.slice(this.lastItem)){
                        if(!itemLoad.isCalculate){
                            itemWithOutPrice.push(itemLoad);
                        }
                    }
                    //console.log('Must send BackGround',this.launchBackground,itemWithOutPrice);
                    this.$store.dispatch('updatePricesBackGroup', itemWithOutPrice);
                    this.launchBackground=false;
                }
            }
            else {
                this.favoritiesList = favoritiesList;
            }
        },
        getItemByCode:function(itemCode){
            return this.$store.getters.getItemByCode(itemCode);
        },
        openItemView:function(item){
            if(item)
                this.$router.push(`/view/${item.Code}`);
        },
        reorderItems: function () {
            this.$store.dispatch('reorderItems',this.orderSelected);
        },
        changeViewMode:function(viewMode){
            this.$store.dispatch('changeItemViewMode',viewMode)
        }
    },

    template: `<div class="produktliste">
                 <div class="header-produktliste">
                    <h4>{{tr('Favorite List')}} ({{favoritiesList.length}})</h4>
                    <div class="items-options">
                        <div class="order-options">
                            <span>{{tr('Order by')}}</span>
                            <select id="soflow" v-model="orderSelected" class="minimal browser-default" @change="reorderItems">
                                <option disabled value="">{{tr('Select One')}}</option>
                                <template v-for="orderField of $store.getters.getSetting.ItemOrdered">
                                    <option :value="orderField.fields.internalId" :selected="orderField==orderSelected">{{tr(orderField.fields.ItemField)}}({{orderField.fields.OrderDirection}})</option>
                                </template>
                            </select>
                        </div>
                        <div class="view-options">
                            <span @click="changeViewMode('module')" v-bind:class="moduleObject">
                                <span>Mosaico</span>
                                <i class="fas fa-th-large fa-1x"></i>
                            </span>
                            <span @click="changeViewMode('list')" v-bind:class="listObject">
                                <span >Lista</span>
                                <i class="fas fa-list-alt fa-1x"></i>
                            </span>
                        </div>
                        <template v-if="favoritiesList.length>0">
                            <paginationComponent key="favorite-pagination-top" currentView="favorites" :items="favoritiesList" v-if="favoritiesList.length>0"></paginationComponent>
                        </template>
                    </div>     
                    <template v-for="item in favoritiesList.slice(initItem,lastItem)">
                        <div :class="{col:true, 's12': viewmode=='list', 's6 m6 l3': viewmode=='module'}" >
                          <template v-if="viewmode=='list'">
                              <itemViewListComponent :key="item.Code" :item="item" @click="openItemView(item)"></itemViewListComponent>
                          </template>
                          <template v-else>
                              <itemViewModuleComponent :key="item.Code" :item="item" @click="openItemView(item)"></itemViewModuleComponent>
                          </template>
                        </div>
                    </template>
                    
                    <div class="items-options">
                        <template v-if="favoritiesList.length>0">
                            <paginationComponent key="favorite-pagination-bottom" currentView="favorites" :items="favoritiesList" v-if="favoritiesList.length>0"></paginationComponent>
                        </template>
                    </div> 
                </div>
            </div>`
});